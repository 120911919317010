import React from "react";
import { useEffect, useState } from "react";
import '../game_page_style.css'
 
function GissellesAdventure({onGameLoad}){
    useEffect(()=>{
        document.title = "Gisselles Adventure"
        onGameLoad()
    },[]);
    return(
        <div className="Game-container">
            <div className="Game">
                <header className="App-title">
                    <h1 className="App-title">Gisselles Adventure</h1>
                </header>
                
                <hr className="Line"></hr>
                

                <section className="generic-section-container container-horizontal">
                    <div className="div-image">
                        <picture className="picture_image">
                            <img className="image-begining" src={require("../../../assets/game_images/ga_portada.png")}></img>
                            <p>Gisselle Braun Von Degureschaft</p>
                        </picture>
                        <div className="short-text-cotainer">

                        </div>
                    </div>
                    <div className="div-short-text">
                        <picture className="picture_image_shortheader">
                            <img className="image-shortheader" src={require("../../../assets/game_images/ga_header.png")}></img>
                        </picture>
                        <p className="short-text-container">
                        Through a blend of visual novel, RPG, and platforming elements and after years of homeschooling, Gisselle must face the real world with her social anxiety disorder in a daunting school environment, where every interaction is a battle and every friendship is an achievement.
                        </p>
                    </div>
                
                </section>
                <section className="generic-section-container container-vertical">
                    <div className="div-complete">
                        <h2>Gisselle's Adventure</h2>
                        <p>
                        A serious video game about social anxiety, narrated from the perspective of Gisselle, a young student who, after years of homeschooling, must face the real world and her own fears. Through a blend of visual novel elements, RPG, and platforming, the player will accompany Gisselle in her struggle to overcome her social phobia in a story that fuses the everyday with the surreal.
                       </p>
                    </div>
                    <div className="div-complete container-horizontal">
                        <div className="div-short-text">
                            <h2>Game features</h2>
                            <ul>
                                <li>4 Main characters</li>
                                <li>1 Playable protagonist</li>
                                <li>Powerups</li>
                                <li>Inventory</li>
                                <li>Map</li>
                                <li>Mini-map</li>
                                <li>15 Levels</li>
                                <li>Side quests</li>
                                <li>Collectibles/Trophies</li>
                                <li>Various Enemies</li>
                                <li>Multiple scenes</li>
                                <li>4 endings</li>
                                <li>Multiple main maps</li>
                                <li>Original music</li>
                            </ul>
                        </div>
                        <div className="div-image">
                            <picture className="picture_image_game_features">
                                <img className="image-begining" src={require("../../../assets/game_images/ga_game_portrait.png")}></img>
                                <label>Multiple characters</label>
                            </picture>
                        </div>
                    </div>
                </section>

                <section className="generic-section-container">
                    <div className="div-complete container-horizontal">
                        <picture className="multiple-media-images-container">
                            <img className="quick-carousel" src={require("../../../assets/game_images/ga_ss1.png")} />
                            <img className="quick-carousel" src={require("../../../assets/game_images/ga_ss2.png")} />
                            <img className="quick-carousel" src={require("../../../assets/game_images/ga_ss3.png")} />
                            <img className="quick-carousel" src={require("../../../assets/game_images/ga_ss4.png")} />
                            <img className="quick-carousel" src={require("../../../assets/game_images/ga_ss5.png")} />
                        </picture>
                    </div>
                </section>
                <br></br>
                <br></br>
                <hr id="hr-divisor-btn"></hr> 
                <br></br>
                <br></br>
                <section className="generic-section-container">
                    <h2>Available on:</h2>
                    <div className="container-horizontal">
                        <ul className="btn-list">
                            <li>
                                <a href="https://store.steampowered.com/app/3196400/Gisselles_Adventure/" target="_blank"  className="btn btn-steam">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-steam" viewBox="0 0 16 16">
                                    <path d="M.329 10.333A8.01 8.01 0 0 0 7.99 16C12.414 16 16 12.418 16 8s-3.586-8-8.009-8A8.006 8.006 0 0 0 0 7.468l.003.006 4.304 1.769A2.2 2.2 0 0 1 5.62 8.88l1.96-2.844-.001-.04a3.046 3.046 0 0 1 3.042-3.043 3.046 3.046 0 0 1 3.042 3.043 3.047 3.047 0 0 1-3.111 3.044l-2.804 2a2.223 2.223 0 0 1-3.075 2.11 2.22 2.22 0 0 1-1.312-1.568L.33 10.333Z"/>
                                    <path d="M4.868 12.683a1.715 1.715 0 0 0 1.318-3.165 1.7 1.7 0 0 0-1.263-.02l1.023.424a1.261 1.261 0 1 1-.97 2.33l-.99-.41a1.7 1.7 0 0 0 .882.84Zm3.726-6.687a2.03 2.03 0 0 0 2.027 2.029 2.03 2.03 0 0 0 2.027-2.029 2.03 2.03 0 0 0-2.027-2.027 2.03 2.03 0 0 0-2.027 2.027m2.03-1.527a1.524 1.524 0 1 1-.002 3.048 1.524 1.524 0 0 1 .002-3.048"/>
                                </svg>
                                <span>Steam</span>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.microsoft.com/store/productId/9NPZ9CM54MWT?ocid=pdpshare" target="_blank" className="btn btn-xbox">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-xbox" viewBox="0 0 16 16">
                                        <path d="M7.202 15.967a8 8 0 0 1-3.552-1.26c-.898-.585-1.101-.826-1.101-1.306 0-.965 1.062-2.656 2.879-4.583C6.459 7.723 7.897 6.44 8.052 6.475c.302.068 2.718 2.423 3.622 3.531 1.43 1.753 2.088 3.189 1.754 3.829-.254.486-1.83 1.437-2.987 1.802-.954.301-2.207.429-3.239.33m-5.866-3.57C.589 11.253.212 10.127.03 8.497c-.06-.539-.038-.846.137-1.95.218-1.377 1.002-2.97 1.945-3.95.401-.417.437-.427.926-.263.595.2 1.23.638 2.213 1.528l.574.519-.313.385C4.056 6.553 2.52 9.086 1.94 10.653c-.315.852-.442 1.707-.306 2.063.091.24.007.15-.3-.319Zm13.101.195c.074-.36-.019-1.02-.238-1.687-.473-1.443-2.055-4.128-3.508-5.953l-.457-.575.494-.454c.646-.593 1.095-.948 1.58-1.25.381-.237.927-.448 1.161-.448.145 0 .654.528 1.065 1.104a8.4 8.4 0 0 1 1.343 3.102c.153.728.166 2.286.024 3.012a9.5 9.5 0 0 1-.6 1.893c-.179.393-.624 1.156-.82 1.404-.1.128-.1.127-.043-.148ZM7.335 1.952c-.67-.34-1.704-.705-2.276-.803a4 4 0 0 0-.759-.043c-.471.024-.45 0 .306-.358A7.8 7.8 0 0 1 6.47.128c.8-.169 2.306-.17 3.094-.005.85.18 1.853.552 2.418.9l.168.103-.385-.02c-.766-.038-1.88.27-3.078.853-.361.176-.676.316-.699.312a12 12 0 0 1-.654-.319Z"/>
                                    </svg>
                                    <span>Xbox</span>
                                 </a>
                            </li>
                            <li>
                                <a href="https://store.epicgames.com/es-MX/p/gisselles-adventure-79ddf9" target="_blank" className="btn btn-epic">
                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0,0,256,256">
                                    <g fill="#ffffff" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none"><g transform="scale(5.12,5.12)"><path d="M10,3c-3.31,0 -6,2.69 -6,6v32.24023l21,6.29883l21,-6.29883v-32.24023c0,-3.31 -2.69,-6 -6,-6zM11,8h4v3h-4v7h3v3h-3v7h4v3h-4c-1.66,0 -3,-1.34 -3,-3v-17c0,-1.66 1.34,-3 3,-3zM17,8h6c1.66,0 3,1.34 3,3v7c0,1.66 -1.34,3 -3,3h-3v10h-3zM28,8h3v23h-3zM36,8h3c1.66,0 3,1.34 3,3v4h-3v-4h-3v17h3v-4h3v4c0,1.66 -1.34,3 -3,3h-3c-1.66,0 -3,-1.34 -3,-3v-17c0,-1.66 1.34,-3 3,-3zM20,11v7h3v-7zM9,34h4c0.55,0 1,0.45 1,1v1h-1v-0.75c0,-0.14 -0.11,-0.25 -0.25,-0.25h-3.5c-0.14,0 -0.25,0.11 -0.25,0.25v3.5c0,0.14 0.11,0.25 0.25,0.25h3.5c0.14,0 0.25,-0.11 0.25,-0.25v-0.75h-1v-1h2v2c0,0.55 -0.45,1 -1,1h-4c-0.55,0 -1,-0.45 -1,-1v-4c0,-0.55 0.45,-1 1,-1zM18,34h1l3,6h-1l-0.5,-1h-4l-0.5,1h-1zM23,34h1l2,4l2,-4h1v6h-1v-4l-1.5,3h-1l-1.5,-3v4h-1zM30,34h5v1h-4v1.5h2v1h-2v1.5h4v1h-5zM37,34h4c0.55,0 1,0.45 1,1v0.5h-1v-0.25c0,-0.14 -0.11,-0.25 -0.25,-0.25h-3.5c-0.14,0 -0.25,0.11 -0.25,0.25v1c0,0.14 0.11,0.25 0.25,0.25h3.75c0.55,0 1,0.45 1,1v1.5c0,0.55 -0.45,1 -1,1h-4c-0.55,0 -1,-0.45 -1,-1v-0.5h1v0.25c0,0.14 0.11,0.25 0.25,0.25h3.5c0.14,0 0.25,-0.11 0.25,-0.25v-1c0,-0.14 -0.11,-0.25 -0.25,-0.25h-3.75c-0.55,0 -1,-0.45 -1,-1v-1.5c0,-0.55 0.45,-1 1,-1zM18.5,35l-1.5,3h3z"></path></g></g>
                                </svg>
                                <span>Epic Games</span>
                                </a>
                            </li>
                            <li>
                                <a href="https://serch-onodera.itch.io/gisselles-adventure-serious-videogame" target="_blank" className="btn btn-itch"  >
                                <svg height="20" width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path fill="#ffffff" d="M71.9 34.8C50.2 47.7 7.4 96.8 7 109.7v21.3c0 27.1 25.3 50.8 48.3 50.8 27.6 0 50.5-22.9 50.5-50 0 27.1 22.2 50 49.8 50s49-22.9 49-50c0 27.1 23.6 50 51.2 50h.5c27.6 0 51.2-22.9 51.2-50 0 27.1 21.5 50 49 50s49.8-22.9 49.8-50c0 27.1 23 50 50.5 50 23 0 48.3-23.8 48.3-50.8v-21.3c-.4-12.9-43.2-62.1-64.9-75C372.6 32.4 325.8 32 256 32S91.1 33.1 71.9 34.8zm132.3 134.4c-22 38.4-77.9 38.7-99.9 .3-13.2 23.1-43.2 32.1-56 27.7-3.9 40.2-13.7 237.1 17.7 269.2 80 18.7 302.1 18.1 379.8 0 31.7-32.3 21.3-232 17.8-269.2-12.9 4.4-42.9-4.6-56-27.7-22 38.5-77.9 38.1-99.9-.2-7.1 12.5-23.1 28.9-51.8 28.9a57.5 57.5 0 0 1 -51.8-28.9zm-41.6 53.8c16.5 0 31.1 0 49.2 19.8a436.9 436.9 0 0 1 88.2 0C318.2 223 332.9 223 349.3 223c52.3 0 65.2 77.5 83.9 144.5 17.3 62.2-5.5 63.7-34 63.7-42.2-1.6-65.5-32.2-65.5-62.8-39.3 6.4-101.9 8.8-155.6 0 0 30.6-23.3 61.2-65.5 62.8-28.4-.1-51.2-1.6-33.9-63.7 18.7-67 31.6-144.5 83.9-144.5zM256 270.8s-44.4 40.8-52.4 55.2l29-1.2v25.3c0 1.6 21.3 .2 23.3 .2 11.7 .5 23.3 1 23.3-.2v-25.3l29 1.2c-8-14.5-52.4-55.2-52.4-55.2z"/>
                                </svg>
                                <span>Itch.io</span>
                                </a>
                            </li>
                            <li>
                                <a href="https://play.google.com/store/apps/details?id=serchonodera.gissellesadventure&hl=en-US&ah=_lGWX6vIriOUtTL_pkO9K74OlHI" target="_blank" className="btn btn-playstore">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-google-play" viewBox="0 0 16 16">
                                       <path d="M14.222 9.374c1.037-.61 1.037-2.137 0-2.748L11.528 5.04 8.32 8l3.207 2.96zm-3.595 2.116L7.583 8.68 1.03 14.73c.201 1.029 1.36 1.61 2.303 1.055zM1 13.396V2.603L6.846 8zM1.03 1.27l6.553 6.05 3.044-2.81L3.333.215C2.39-.341 1.231.24 1.03 1.27"/>
                                    </svg>
                                <span>Google PlayStore</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </section>
                <section className="generic-section-container">
                    <div className="container-vertical">
                    <iframe frameborder="0" src="https://itch.io/embed/3089802?linkback=true&amp;bg_color=39095a&amp;fg_color=ffffff&amp;link_color=1c21a7&amp;border_color=ffffff" width="208" height="167"><a href="https://serch-onodera.itch.io/gisselles-adventure-serious-videogame">Gisselle's Adventure by Serch Onodera</a></iframe>
                    </div>
                </section>
                <section className="generic-section-container">
                    <br></br>
                    <hr className="last-line" width="80%"></hr>
                    <br></br>
                    <h2>TRAILER</h2>
                    <br></br>
                    <br></br>
                    <iframe className="video-iframe" height="315" src="https://www.youtube.com/embed/9ysoVJZhxnE?si=Lfr7Ms9U-395Ujr2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </section>
                <footer className="generic-section-container">
                    <div className="container-horizontal">
                        <ul id="social-media-btn-list">
                            <li>
                                <a href="https://x.com/GissellesA24080" target="_blank" className="btn-socialmedia">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 16 16">
                                <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
                                </svg>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/@OnoderaDev" target="_blank" className="btn-socialmedia">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-youtube" viewBox="0 0 16 16">
                                    <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z"/>
                                    </svg>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/serch.onodera/" target="_blank" className="btn-socialmedia">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-instagram" viewBox="0 0 16 16">
                                <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
                                </svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <small>Designed and developed by Serch Onodera. ©2025. All rights reserved</small>       
                </footer>   
            </div>
        </div>
    );
}

export default GissellesAdventure;
import React from "react";
import { useEffect, useState } from "react";

import GameCard from "../../components/card_game";
import data_games from '../../assets/data/data_gamecards.json'
import Footer from "../../components/footer";


import '../../App.css';
import '../../assets/styles/games.css';
import '../../components/footer/footer.css'

function Games({onGames}){
    const [gameList, setGameList] = useState()
    
    useEffect(()=>{
        document.title = "Games";
        fill_game_list()
        onGames()
    },[]);
    function fill_game_list(){
        setGameList(data_games.data_games);
        console.log(data_games)
    }

    return(
        <div className="App gamespage">
            <div className="gameList">
            <h1 className="title-section">{"<Games/>"}</h1>
            <br></br>
            <hr></hr>
            <br></br>
                <div className="responsive-list">
                    {
                        gameList?
                        gameList.map((item, index)=>(
                            <GameCard data={item} key={index}/>
                        ))
                        :
                        <div></div>
                    }
                </div>
            </div>
            <div className="footer-to-bottom">
             <Footer/>
            </div>
        </div>
    );
}

export default Games;
import React from "react";
import { useEffect, useState } from "react";

import "./game_card.css";
import { Link } from "react-router-dom";

function GameCard({data}){
    useEffect(() => {

    },[]);

    return(
        <div className="game_card">
            <Link to="/games/gisselles_adventure">
            <picture className="picture-card">
                <small className="description">
                    {data.short_description}
                </small>
                <img src={require(`/src/assets/game_images/${data.img}`)}></img>
                <span>{data.name}</span>
            </picture>
            </Link>
        </div>
    );
}

export default GameCard;
import React from "react";
import { useEffect, useState } from "react";

import "../card/card.css"


function CardExperience({data, onMoreInfo}){
    const [cardInfo, setCardInfo] = useState({});
    const [currentIMG, setCurrentIMG] = useState();
    const [index, setIndex] = useState(0);

    useEffect(()=>{
        fill_data()
       
       const interaval = setInterval(()=>{
            change_image()
        }, 5000);

        return ()=> clearInterval(interaval)
    },[]);

    function fill_data(){
        setCardInfo(data);
        setCurrentIMG(data.preview_images);
    }

    function change_image(){
        let image =  document.querySelectorAll(".img-previewer")
        image.forEach(i=>{
            i.classList.add("image_changing")
            i.classList.remove(".image_prev")

        })
        setTimeout(()=>{
            image.forEach(i=>{
            i.classList.remove("image_changing")
            i.classList.add(".image_prev")
            setIndex(prevIndex => (prevIndex < 2? prevIndex + 1:0))
             });
        },300)
    }

    function displayModal(data){
        onMoreInfo(data)
    }

    return (
        <div className="card-body">
            <picture>
                {   
                    cardInfo.preview_images?
                        <img className="img-previewer image_prev" src={require(`/src/assets/images/experience/${currentIMG[index]}`)} alt={cardInfo.project_name+".png"}></img>
                        :
                        <img className="image_prev" alt="loading.png"></img>
                }
            </picture>
            <div className="body-container">
                <div className="info-header">
                    <picture>
                        {
                            cardInfo.icon_main_tech?
                                <img src={require(`/src/assets/images/skills/hard-skills/${cardInfo.icon_main_tech}`)} alt="tech.png"/>
                            :
                                <img  alt="loading"/>
                        }
                    </picture>
                    <span><b>{cardInfo.position}</b></span>
                </div>
                <div className="info-technology">
                    <span><b>Tech: </b>{cardInfo.technologies}</span>
                </div>
                <hr></hr>
                <button onClick={() => displayModal(data)}> More info </button>
            </div>
        </div>
    )
}

export default CardExperience;
import React from "react";
import { useEffect, useState } from "react";

import Card from "../../components/card"
import Modal from "../../components/modal"

import "../../assets/styles/projects.css";
import data_projects from "../../assets/data/data_projects.json" 
import Footer from "../../components/footer";
function Projects({onProjects}){
    const [dataProject, setDataProjects] = useState()
    const [dataModal, setDataModal] = useState({})
    useEffect(()=>{
        document.title = "Projects";
        onProjects()
        fillDataProject()
    },[]);
    function fillDataProject(){
        setDataProjects(data_projects);
    }
    function displayModal(prop){
        const modal_controller = document.getElementById("modal-displayer");
        modal_controller.click();
        // console.log(prop);
        setDataModal(prop);
    }
    return(
        <div className="App projects-main">
            <Modal data={dataModal}/>
            <div className="projects-container">
               <h1>{"<Projects/>"}</h1>
               <br></br>
               <hr></hr> 
               <br></br>
               <div className="card-container">
                {
                    data_projects.data_projects.map((item, index) => (
                        <Card prop={item} onMoreinfo={displayModal} key={index}/>
                    ))
                }
               </div>
            </div>
            <Footer></Footer>
        </div>
    );
}

export default Projects;
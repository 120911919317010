import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { isVisible } from "@testing-library/user-event/dist/utils";
import { click } from "@testing-library/user-event/dist/click";

import "../modal/modal.css"

function ModalExperience({data}){
    const [index, setIndex] = useState(0);

    useEffect
    (() => {
        console.log(data)
    },[])

    function hideModal(){
       let modalActivator = document.getElementById("modal-displayer")
       modalActivator.click()
    }


    function set_lisneter(){
       let modalBody = document.getElementById("id-modal-shade")
       let modalStatus = document.getElementById("modal-displayer").checked
       

       if(!modalStatus){
           document.removeEventListener("mousedown", listenOutsideClicks);
           console.log("Listener eliminado");
           return;
        }
        console.log("listener agregado, status visible:", modalStatus)
        
       function listenOutsideClicks(event){
           event.stopPropagation();
            hideModal()
       };

       modalBody.addEventListener("mousedown", listenOutsideClicks,{once:true});
       
       setTimeout(() => {
           setDefaultFocused()
           setIndex(0)
       },300)
    }
    function setDefaultFocused(){
        let modalBody = document.getElementById("img-indictor-0");
        modalBody.checked = true;
    }
    function selectoToRight(){
        let limit = data.preview_images.length-2
        let i = index
        
        if(i> limit) return;
        
        i++
        let id = "img-indictor-"+ i.toString()
        let central = document.getElementById("central-img");
        let right = document.getElementById("right-img");
        

        let indicator = document.getElementById(id);
        indicator.checked = true;

        central.classList.add("central_to_right")
        right.classList.add("right_to_right")
        
        setTimeout(() => {
            central.classList.remove("central_to_right")
            right.classList.remove("right_to_right")
            setIndex(i)
        }, 300)
        // indicator.checked = true;
    }

    function selectoToLeft(){
        let limit = 1
        let i = index
        console.log(i)
        if(i <= 0) return;

        i--

        let id = "img-indictor-"+ i.toString()
        
        setIndex(i)

        let indicator = document.getElementById(id);
        indicator.checked = true;

        let central = document.getElementById("central-img");
        let left = document.getElementById("left-img");

        central.classList.add("central_to_left")
        left.classList.add("left_to_left")
        
        setTimeout(() => {
            central.classList.remove("central_to_left")
            left.classList.remove("left_to_left")
            setIndex(i)
        }, 300)
    }

    function directValue(v) {
        if(v==index) return;
        let central = document.getElementById("central-img");
        let left = document.getElementById("left-img");
        let right = document.getElementById("right-img");

        central.classList.add("blink")
        left.classList.add("blink")
        right.classList.add("blink")

        setTimeout(() => {
            central.classList.remove("blink")
            left.classList.remove("blink")
            right.classList.remove("blink")
            setIndex(v)
        }, 300)
    }

    return(
        <div className="modal-page">
            <div id="id-modal-shade" className="modal-shade"></div>
            <input id="modal-displayer" hidden type="checkbox" onClick={()=>{set_lisneter()}}/>
            <div id="id-modal-body"  className="modal-body">
                <div className="modal-header">
                    <h1>{data.position}</h1>
                    <button onClick={hideModal}>
                        <svg fill="none" viewBox="0 0 24 24" height="1.5em" width="1.5em">
                        <path
                            fill="currentColor"
                            d="M16.34 9.322a1 1 0 10-1.364-1.463l-2.926 2.728L9.322 7.66A1 1 0 007.86 9.024l2.728 2.926-2.927 2.728a1 1 0 101.364 1.462l2.926-2.727 2.728 2.926a1 1 0 101.462-1.363l-2.727-2.926 2.926-2.728z"
                        />
                        <path
                            fill="currentColor"
                            fillRule="evenodd"
                            d="M1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12zm11 9a9 9 0 110-18 9 9 0 010 18z"
                            clipRule="evenodd"
                        />
                        </svg>
                    </button>
                </div>
                <div className="modal-content">
                    <div className="modal-carousel-container">
                        <button onClick={selectoToLeft}>{"<"}</button>
                        <div className="carousel-body">
                            <div id="carousel-images" className="modal-carousel-images">
                                <picture  id="left-img" className="normal-img">
                                    {
                                        (data.preview_images)?
                                        <img src={require(`/src/assets/images/experience/${data.preview_images[index]}`)}></img>:
                                        <img alt="Loading..."></img>
                                    }
                                </picture>
                                <picture id="central-img" className="focused-img">
                                    {
                                        (data.preview_images)?
                                        <img  src={require(`/src/assets/images/experience/${data.preview_images[index]}`)}></img>:
                                        <img alt="Loading..."></img>
                                    }
                                </picture>
                                <picture id="right-img" className="normal-img">
                                    {
                                        (data.preview_images)?
                                        <img  src={require(`/src/assets/images/experience/${data.preview_images[index]}`)}></img>:
                                        <img alt="Loading..."></img>
                                    }
                                </picture>
                            </div>
                            <div className="images-indicator">
                                <ul>
                                    {
                                        (data.preview_images)?

                                        data.preview_images.map((item,index)=>(
                                            <li className="btn-to-select-img" key={index}>
                                                <label htmlFor={"img-indictor-"+index} onClick={() =>directValue(index)}></label>
                                                <input id={"img-indictor-"+index} value={index} hidden type="radio" name="img-indicator"/>
                                            </li>

                                        ))
                                        :
                                        <li className="btn-to-select-img">
                                            <label htmlFor="img-indictor-n"></label>
                                            <input id={"img-indictor-n"} value={"n"} hidden type="radio" name="img-indicator"/>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </div>
                        <button onClick={selectoToRight}>{">"}</button>
                    </div>
                    <div className="modal-body-content">
                        <span dangerouslySetInnerHTML={{__html: data.description}}></span>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="modal-footer-btn-container">
                        <div id="btn-goto" dangerouslySetInnerHTML={{__html:  data.button_goto}} onClick={hideModal}></div>
                        <button onClick={hideModal}>close</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModalExperience;
import logo from './logo.svg';
import ReactDOM from 'react-dom';
// import './App.css';
import './assets/styles/navigator_menu.css';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, Link } from 'react-router-dom';
import { useEffect, useState } from 'react';


import SearchBar from './components/search_bar'
import Home from './pages/home/'
import Projects from './pages/projects/'
import Games from './pages/games/'
import Arts from './pages/arts/'
import Experience from './pages/experience/'
import GissellesAdventure from './pages/games/gisselles_adventure';
import GA_policies from './pages/games/gisselles_adventure/ga_policies';

function App() {
  function option1(){document.getElementById('main-menu-op1').checked = true};
  function option2(){document.getElementById('main-menu-op2').checked = true};
  function option3(){document.getElementById('main-menu-op3').checked = true};
  function option4(){document.getElementById('main-menu-op4').checked = true};
  function option5(){document.getElementById('main-menu-op5').checked = true};

  return (
    <div className="App">    
    {/* <Home></Home> */}
    {/* <GissellesAdventure></GissellesAdventure> */}
      <Router>
        <header className="header-menu">
          
          <div className="container-logo">
            <picture className='icon-nav-menu'>
              <img className='icon-navmenu-png' alt="onodera icon" src={require("./logo.png")}/>
            </picture>
            <div className='container-name-title'>
              <span>Serch Onodera</span>
              <small>Software Engineer</small>
            </div>
              <SearchBar/>
          </div>

          <ul className="ul-menu-buttons">
            <li>
                <Link className="label-btn" to={"/"} onClick={option1}>
                  <svg viewBox="0 0 1024 1024" fill="currentColor" height="1em"width="1em">
                    <path d="M946.5 505L534.6 93.4a31.93 31.93 0 00-45.2 0L77.5 505c-12 12-18.8 28.3-18.8 45.3 0 35.3 28.7 64 64 64h43.4V908c0 17.7 14.3 32 32 32H448V716h112v224h265.9c17.7 0 32-14.3 32-32V614.3h43.4c17 0 33.3-6.7 45.3-18.8 24.9-25 24.9-65.5-.1-90.5z" />
                  </svg>
                  <span>Home</span>
                </Link>
                <input id='main-menu-op1'  type="radio" name="radio_main_menu" hidden/>
            </li>
            <li>
                <Link className='label-btn' to={"/projects/"} onClick={option2}>
                  <svg viewBox="0 0 1024 1024" fill="currentColor" height="1em" width="1em">
                   <path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM368 744c0 4.4-3.6 8-8 8h-80c-4.4 0-8-3.6-8-8V280c0-4.4 3.6-8 8-8h80c4.4 0 8 3.6 8 8v464zm192-280c0 4.4-3.6 8-8 8h-80c-4.4 0-8-3.6-8-8V280c0-4.4 3.6-8 8-8h80c4.4 0 8 3.6 8 8v184zm192 72c0 4.4-3.6 8-8 8h-80c-4.4 0-8-3.6-8-8V280c0-4.4 3.6-8 8-8h80c4.4 0 8 3.6 8 8v256z" />
                  </svg>
                  <span>Projects</span>
                </Link>
                <input id='main-menu-op2' type="radio" name="radio_main_menu" hidden/>
            </li>
            <li>
                <Link className='label-btn' to={"/games/"} onClick={option3}>
                  <svg fill="none" viewBox="0 0 24 24" height="1em" width="1em">
                    <path fill="currentColor" d="M15.47 11.293a1 1 0 10-1.415 1.414 1 1 0 001.415-1.414zM16.177 9.172a1 1 0 111.414 1.414 1 1 0 01-1.414-1.414zM19.712 11.293a1 1 0 10-1.414 1.414 1 1 0 001.414-1.414zM16.177 13.414a1 1 0 111.414 1.415 1 1 0 01-1.414-1.415zM6 13H4v-2h2V9h2v2h2v2H8v2H6v-2z"/>
                    <path fill="currentColor" fillRule="evenodd" d="M7 5a7 7 0 000 14h10a7 7 0 100-14H7zm10 2H7a5 5 0 000 10h10a5 5 0 000-10z"clipRule="evenodd"/>
                  </svg>
                  <span>Games</span>
                </Link>
                <input id='main-menu-op3' type="radio" name="radio_main_menu" hidden/>
            </li>
            <li>
              <Link className="label-btn" to={"/arts"} onClick={option4}>
                <svg viewBox="0 0 24 24" fill="currentColor"height="1em"width="1em">
                  <path d="M7.061 22c1.523 0 2.84-.543 3.91-1.613 1.123-1.123 1.707-2.854 1.551-4.494l8.564-8.564a3.123 3.123 0 00-.002-4.414c-1.178-1.18-3.234-1.18-4.412 0l-8.884 8.884c-1.913.169-3.807 1.521-3.807 3.919 0 .303.021.588.042.86.08 1.031.109 1.418-1.471 2.208a1.001 1.001 0 00-.122 1.717C2.52 20.563 4.623 22 7.061 22c-.001 0-.001 0 0 0zM18.086 4.328a1.144 1.144 0 011.586.002 1.12 1.12 0 010 1.584L12 13.586 10.414 12l7.672-7.672zM6.018 16.423c-.018-.224-.037-.458-.037-.706 0-1.545 1.445-1.953 2.21-1.953.356 0 .699.073.964.206.945.475 1.26 1.293 1.357 1.896.177 1.09-.217 2.368-.956 3.107C8.865 19.664 8.049 20 7.061 20H7.06c-.75 0-1.479-.196-2.074-.427 1.082-.973 1.121-1.989 1.032-3.15z" />
                </svg>
                <span>Arts</span>
              </Link>
              <input id='main-menu-op4' type="radio" name="radio_main_menu" hidden/>
            </li>
            <li>
                <Link className='label-btn' to={"/experience/"} onClick={option5}>
                  <svg
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    height="1em"
                    width="1em"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M10.414 3l2 2H21a1 1 0 011 1v14a1 1 0 01-1 1H3a1 1 0 01-1-1V4a1 1 0 011-1h7.414zM13 9h-2v6h5v-2h-3V9z" />
                  </svg>
                  <span>Experience</span>
                </Link>
                <input id='main-menu-op5' type="radio" name="radio_main_menu" hidden/>
            </li>
          </ul>
        </header>

          <Routes>
            {/* <Route path="/" element={<GissellesAdventure />} /> */}
            <Route path="/" element={<Home onGame={option3} onHome={option1} />} />
            <Route path="home/" element={<Home onGame={option3} onHome={option1}/>} exact/>
            <Route path="projects/" element={<Projects onProjects={option2} />} exact/>
            <Route path="games/" element={<Games onGames={option3} />} exact/>
            <Route path="arts/" element={<Arts onArts={option4}/>} exact/> exact
            <Route path="experience/" element={<Experience onExperience={option5} />} exact/>
            <Route path="games/gisselles_adventure" element={<GissellesAdventure onGameLoad={option3} />}/>
            <Route path="games/gisselles_adventure/privacy_policies" element={<GA_policies/>}/>
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
      </Router>
    </div>
  );
}

export default App;

import React from "react";
import { useState, useEffect } from "react";

import './modal_arts.css'

function ModalArts({data}){
    const [modalData, setModaldata] = useState({});
    useEffect(()=>{
        setModaldata(data);
        console.log(data)
    },[])

    function hide_modal(){
        let modal = document.getElementById("modal-activator");
        modal.click()
    }
    return(
        <div className="modal-item" onClick={hide_modal}>
            <div className="shade-modal"></div>
            <input id="modal-activator" type="checkbox" hidden></input>
            <div className="modal-container">
                <button >
                    <svg
                        viewBox="0 0 1024 1024"
                        fill="currentColor"
                        height="2em"
                        width="2em" >
                        <path d="M391 240.9c-.8-6.6-8.9-9.4-13.6-4.7l-43.7 43.7L200 146.3a8.03 8.03 0 00-11.3 0l-42.4 42.3a8.03 8.03 0 000 11.3L280 333.6l-43.9 43.9a8.01 8.01 0 004.7 13.6L401 410c5.1.6 9.5-3.7 8.9-8.9L391 240.9zm10.1 373.2L240.8 633c-6.6.8-9.4 8.9-4.7 13.6l43.9 43.9L146.3 824a8.03 8.03 0 000 11.3l42.4 42.3c3.1 3.1 8.2 3.1 11.3 0L333.7 744l43.7 43.7A8.01 8.01 0 00391 783l18.9-160.1c.6-5.1-3.7-9.4-8.8-8.8zm221.8-204.2L783.2 391c6.6-.8 9.4-8.9 4.7-13.6L744 333.6 877.7 200c3.1-3.1 3.1-8.2 0-11.3l-42.4-42.3a8.03 8.03 0 00-11.3 0L690.3 279.9l-43.7-43.7a8.01 8.01 0 00-13.6 4.7L614.1 401c-.6 5.2 3.7 9.5 8.8 8.9zM744 690.4l43.9-43.9a8.01 8.01 0 00-4.7-13.6L623 614c-5.1-.6-9.5 3.7-8.9 8.9L633 783.1c.8 6.6 8.9 9.4 13.6 4.7l43.7-43.7L824 877.7c3.1 3.1 8.2 3.1 11.3 0l42.4-42.3c3.1-3.1 3.1-8.2 0-11.3L744 690.4z" />
                    </svg>
                </button>
                <picture className="modal-container-img">
                    {
                        (data.source)?
                            <img src={require(`/src/assets/images/arts/${data.source}`)}  className="img-modal" alt="drawing.png"/>
                            :
                            <img  className="img-modal" alt="Loading"/>
                    }
                </picture>
            </div>
        </div>
    );
}

export default ModalArts;
import React from "react";
import { useEffect, useStyles } from "react";
import { Link } from "react-router-dom";

import '../../App.css';
import '../../assets/styles/home.css';
import logo from '../../logo.png';
import Footer from "../../components/footer";
function Home({onGame, onHome}){
    useEffect(() =>{
        document.title = "Onodera dev";
        onHome()
    },[]);

    function go_to_game(){
        onGame()
    }
    return(
        <div className="main-home">
            <section id="section1" className="section-home"> 
                <div className="generic-btn-container">
                    <div className="btn-shade-container">
                        <div className="btn-shade"></div>
                        <Link className="btn-game" to="/games/gisselles_adventure/" onClick={go_to_game}>Gisselle's Adventure</Link>
                    </div>
                </div>
                <div className="header-page elements-container">
                    <div className="header-title-container">
                        <h1>SOFTWARE ENGINEER</h1>
                        <h2>{"<PORTFOLIO/>"}</h2>
                        <br></br>
                        <a className="email-btn" href="mailto:onoderaserch117@gmail.com">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-envelope-at-fill" viewBox="0 0 16 16">
                                <path d="M2 2A2 2 0 0 0 .05 3.555L8 8.414l7.95-4.859A2 2 0 0 0 14 2zm-2 9.8V4.698l5.803 3.546zm6.761-2.97-6.57 4.026A2 2 0 0 0 2 14h6.256A4.5 4.5 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586zM16 9.671V4.697l-5.803 3.546.338.208A4.5 4.5 0 0 1 12.5 8c1.414 0 2.675.652 3.5 1.671"/>
                                <path d="M15.834 12.244c0 1.168-.577 2.025-1.587 2.025-.503 0-1.002-.228-1.12-.648h-.043c-.118.416-.543.643-1.015.643-.77 0-1.259-.542-1.259-1.434v-.529c0-.844.481-1.4 1.26-1.4.585 0 .87.333.953.63h.03v-.568h.905v2.19c0 .272.18.42.411.42.315 0 .639-.415.639-1.39v-.118c0-1.277-.95-2.326-2.484-2.326h-.04c-1.582 0-2.64 1.067-2.64 2.724v.157c0 1.867 1.237 2.654 2.57 2.654h.045c.507 0 .935-.07 1.18-.18v.731c-.219.1-.643.175-1.237.175h-.044C10.438 16 9 14.82 9 12.646v-.214C9 10.36 10.421 9 12.485 9h.035c2.12 0 3.314 1.43 3.314 3.034zm-4.04.21v.227c0 .586.227.8.581.8.31 0 .564-.17.564-.743v-.367c0-.516-.275-.708-.572-.708-.346 0-.573.245-.573.791"/>
                            </svg>
                            Email contact
                        </a>
                    </div>
                    <div className="header-carousel-container">
                        <picture id="img1" className="picture-header">
                            <img className="sct1-carousel-img" src={require("../../assets/images/rotative_card_1.png")} alt="carousel img"></img>
                        </picture>
                        <picture id="img2" className="picture-header">
                            <img className="sct1-carousel-img" src={require("../../assets/images/rotative_card_2.png")} alt="carousel img"></img>
                        </picture>
                        <picture id="img3" className="picture-header">
                            <img className="sct1-carousel-img" src={require("../../assets/images/rotative_card_3.png")} alt="carousel img"></img>
                        </picture>
                        <picture id="img4" className="picture-header">
                            <img className="sct1-carousel-img" src={require("../../assets/images/rotative_card_4.png")} alt="carousel img"></img>
                        </picture>
                        <picture id="img5" className="picture-header">
                            <img className="sct1-carousel-img" src={require("../../assets/images/rotative_card_5.png")} alt="carousel img"></img>
                        </picture>
                    </div>
                </div>
                <div className="butons-to-sections">
                        <ul>
                            <li><a href="#section2">About me</a></li>
                            <li><a href="#section3">Soft Skills</a></li>
                            <li><a href="#section4">Hard-skills</a></li>
                        </ul>
                    </div>                     
            </section>


            <section id="section2" className="section-home">
                <div className="inner-section-container">
                    <h1>About me</h1>
                    <div className="about-me-container first-aboutme">
                        <h2>Description</h2>
                        <p>
                            Hello! My name is Onodera Dev, a 23-year-old software engineer with a bachelor's degree in Software Engineering. I specialize in front-end development but aim to grow as a full-stack developer because I enjoy working on both the front-end and back-end aspects of the development process.
                            I have a solid foundation in software engineering principles, including web and mobile programming, and some design knowledge that enhances my ability to create user-friendly and visually appealing applications.
                        </p>
                        <br></br>
                        <h3>Strengths</h3>
                        <ul>
                            <li>Responsibility: I am highly dedicated and work diligently to achieve my goals and deliver quality results.</li>
                            <li>Freelance and Independent Projects: I have successfully completed medium-scale freelance and independent projects, which have helped me gain practical experience and strengthen my problem-solving skills.</li>
                        </ul>
                        <br></br>
                        <h3>Areas for Growth</h3>
                        <ul>
                            <li>Limited experience with large-scaleprojects, but I am eager to learn and improve by taking on new challenges.</li>
                        </ul>
                    </div>
                    <div className="about-me-container second-aboutme">
                        <h2>Hobbies</h2>
                        <div className="hobbies-list">
                            <div className="hobby_card">
                                Developing Video Games:
                                <span>I enjoy creating immersive worlds and engaging gameplay mechanics as part of my passion for game development.</span>
                            </div>
                            <div className="hobby_card">
                                Drawing: 
                                <span>Exploring creativity through drawing helps me improve my design skills and visualization for projects.</span>
                            </div>
                            <div className="hobby_card">
                                Reading: 
                                <span>Reading I love reading books, especially those that expand my knowledge in technology, programming, and personal growth.</span>
                            </div>
                            <div className="hobby_card">
                                Practicing New Coding Techniques: 
                                <span>I dedicate time to learning and experimenting with new coding techniques and tools to enhance my skills and stay up-to-date with industry trends.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section id="section3" className="section-home">
            
                <div className="inner-section-container">
                    <h1>Soft-skills</h1>
                    <br></br>
                    <hr></hr>
                    <br></br>
                    <div className="inner-section-infinitescroll">
                        <div className="item-cont-sroll">
                            <div className="skill-scroll1">
                                <picture className="img-skill-cont">
                                    <span>Adaptability</span>
                                    <img src={require('../../assets/images/skills/soft-skills/adaptability.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Communication</span>
                                    <img src={require('../../assets/images/skills/soft-skills/communication.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Empathy</span>
                                    <img src={require('../../assets/images/skills/soft-skills/empathy.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Hard working</span>
                                    <img src={require('../../assets/images/skills/soft-skills/hard_working.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Organized</span>
                                    <img src={require('../../assets/images/skills/soft-skills/organized.webp')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Problen solving</span>
                                    <img src={require('../../assets/images/skills/soft-skills/problem-solving.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Resilience</span>
                                    <img src={require('../../assets/images/skills/soft-skills/resilience.jpg')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Stress management</span>
                                    <img src={require('../../assets/images/skills/soft-skills/stress_m.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Teamwork</span>
                                    <img src={require('../../assets/images/skills/soft-skills/teamwork.png')} className="img-skill" alt="skill.png"/>
                                </picture>

                                {/* duplicated */}
                                <picture className="img-skill-cont">
                                    <span>Adaptability</span>
                                    <img src={require('../../assets/images/skills/soft-skills/adaptability.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Communication</span>
                                    <img src={require('../../assets/images/skills/soft-skills/communication.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Empathy</span>
                                    <img src={require('../../assets/images/skills/soft-skills/empathy.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Hard working</span>
                                    <img src={require('../../assets/images/skills/soft-skills/hard_working.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Organized</span>
                                    <img src={require('../../assets/images/skills/soft-skills/organized.webp')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Problen solving</span>
                                    <img src={require('../../assets/images/skills/soft-skills/problem-solving.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Resilience</span>
                                    <img src={require('../../assets/images/skills/soft-skills/resilience.jpg')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Stress management</span>
                                    <img src={require('../../assets/images/skills/soft-skills/stress_m.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Teamwork</span>
                                    <img src={require('../../assets/images/skills/soft-skills/teamwork.png')} className="img-skill" alt="skill.png"/>
                                </picture>

                            </div>
                        </div>
                        <div className="item-cont-sroll">
                            <div className="skill-scroll2">
                                 <picture className="img-skill-cont">
                                    <span>Teamwork</span>
                                    <img src={require('../../assets/images/skills/soft-skills/teamwork.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Stress Management</span>
                                    <img src={require('../../assets/images/skills/soft-skills/stress_m.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Resilience</span>
                                    <img src={require('../../assets/images/skills/soft-skills/resilience.jpg')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Problem Solving</span>
                                    <img src={require('../../assets/images/skills/soft-skills/problem-solving.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                     <span>Organized</span>
                                    <img src={require('../../assets/images/skills/soft-skills/organized.webp')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Hardworking</span>
                                    <img src={require('../../assets/images/skills/soft-skills/hard_working.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                     <span>Empathy</span>
                                    <img src={require('../../assets/images/skills/soft-skills/empathy.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Communication</span>
                                    <img src={require('../../assets/images/skills/soft-skills/communication.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Adaptability</span>
                                    <img src={require('../../assets/images/skills/soft-skills/adaptability.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                {/* duplicated */}
                                <picture className="img-skill-cont">
                                    <span>Teamwork</span>
                                    <img src={require('../../assets/images/skills/soft-skills/teamwork.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Stress Management</span>
                                    <img src={require('../../assets/images/skills/soft-skills/stress_m.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Resilience</span>
                                    <img src={require('../../assets/images/skills/soft-skills/resilience.jpg')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Problem Solving</span>
                                    <img src={require('../../assets/images/skills/soft-skills/problem-solving.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                     <span>Organized</span>
                                    <img src={require('../../assets/images/skills/soft-skills/organized.webp')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Hardworking</span>
                                    <img src={require('../../assets/images/skills/soft-skills/hard_working.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                     <span>Empathy</span>
                                    <img src={require('../../assets/images/skills/soft-skills/empathy.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Communication</span>
                                    <img src={require('../../assets/images/skills/soft-skills/communication.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Adaptability</span>
                                    <img src={require('../../assets/images/skills/soft-skills/adaptability.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                            </div>
                        </div>
                    </div>
                </div>
            
            </section>


            <section id="section4" className="section-home">

                <div className="inner-section-container">
                    <h1>Hard-skills</h1>
                    <br></br>
                    <hr></hr>
                    <br></br>
                    <div className="inner-section-infinitescroll">
                        <div className="item-cont-sroll">
                            <div className="hardskill-scroll1">
                                <picture className="img-skill-cont">
                                    <span>Android Studio</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/astudio.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Blazor</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/Blazor.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Blender</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/blender.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>C</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/c.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>English</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/english.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Git</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/git.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>GitHub</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/github.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Godot Engine</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/godot.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Java</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/java.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Web development Js</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/js.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Kotlin</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/kotlin.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Node js</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/nodejs.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>PHP</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/phpicon.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>PHP my admin</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/phpmyadmin.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Adove Photoshop</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/ps.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>React js</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/reactjs.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Reactnative</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/reactnative.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>C#</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/sechar.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>C++</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/siplusplus.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Sql Server</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/sqlsever.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Sql workbench</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/sql-workbench.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Unity</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/unity.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Unreal Engine</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/unreal_engine.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Vue js</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/vuejs.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>XAMARIN (uwp)</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/xamarin.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                {/* separation */}
                                <picture className="img-skill-cont">
                                    <span>Android Studio</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/astudio.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Blazor</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/Blazor.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Blender</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/blender.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>C</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/c.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>English</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/english.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Git</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/git.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>GitHub</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/github.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Godot Engine</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/godot.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Java</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/java.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Web development Js</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/js.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Kotlin</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/kotlin.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Node js</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/nodejs.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>PHP</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/phpicon.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>PHP my admin</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/phpmyadmin.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Adove Photoshop</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/ps.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>React js</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/reactjs.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Reactnative</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/reactnative.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>C#</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/sechar.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>C++</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/siplusplus.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Sql Server</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/sqlsever.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Sql workbench</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/sql-workbench.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Unity</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/unity.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Unreal Engine</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/unreal_engine.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Vue js</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/vuejs.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>XAMARIN (uwp)</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/xamarin.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                            </div>
                        </div>
                        <div className="item-cont-sroll">
                            <div className="hardskill-scroll2">
                            <picture className="img-skill-cont">
                                    <span>Android Studio</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/astudio.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Blazor</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/Blazor.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Blender</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/blender.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>C</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/c.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>English</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/english.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Git</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/git.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>GitHub</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/github.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Godot Engine</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/godot.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Java</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/java.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Web development Js</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/js.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Kotlin</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/kotlin.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Node js</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/nodejs.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>PHP</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/phpicon.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Adove Photoshop</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/ps.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>PHP my admin</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/phpmyadmin.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>React js</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/reactjs.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>C#</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/sechar.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>C++</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/siplusplus.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Reactnative</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/reactnative.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Unity</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/unity.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Unreal Engine</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/unreal_engine.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Sql Server</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/sqlsever.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Vue js</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/vuejs.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>XAMARIN (uwp)</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/xamarin.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Sql workbench</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/sql-workbench.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                {/* separation */}
                                <picture className="img-skill-cont">
                                    <span>Android Studio</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/astudio.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Blazor</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/Blazor.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Blender</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/blender.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>C</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/c.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>English</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/english.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Git</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/git.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>GitHub</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/github.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Godot Engine</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/godot.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Java</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/java.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Web development Js</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/js.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Kotlin</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/kotlin.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Node js</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/nodejs.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>PHP</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/phpicon.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Adove Photoshop</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/ps.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>PHP my admin</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/phpmyadmin.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>React js</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/reactjs.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>C#</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/sechar.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>C++</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/siplusplus.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Reactnative</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/reactnative.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Unity</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/unity.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Unreal Engine</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/unreal_engine.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Sql Server</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/sqlsever.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Vue js</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/vuejs.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>XAMARIN (uwp)</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/xamarin.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                                <picture className="img-skill-cont">
                                    <span>Sql workbench</span>
                                    <img  src={require('../../assets/images/skills/hard-skills/sql-workbench.png')} className="img-skill" alt="skill.png"/>
                                </picture>
                            </div>
                        </div>
                    </div>
                </div>

            </section>


            <Footer/>
        </div>
    );
}

export default Home;
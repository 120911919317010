import React from "react";

import { useState, useEffect } from "react";

import './card_art.css';

function ArtCard ({art_class='my-default', data, onModalActivate}){
    const [imgOBJ, setImgObjs] = useState({})
    useEffect(()=>{
        setImgObjs(data)
    },[])
   
    function activate_modal(){
        onModalActivate(data);
    }

    return (
        <div className={`card_art_container ${art_class}`} onClick={()=>activate_modal()}>
            <picture className="card_body">
                {
                    (imgOBJ.source)?
                        <>
                             <small>{imgOBJ.description}</small>
                            <img src={require(`/src/assets/images/arts/${imgOBJ.source}`)}/> 
                        </>
                            : <img alt="loading..."/>
                }
            </picture>
        </div>
    )
}

export default ArtCard;
import React from "react";
import { useEffect, useState } from "react";
import { Link  } from "react-router-dom";

import './search.css';
import data_options from "../../assets/data/data_generic_for_search.json"

function SearchComponent(){
    const [dataList, setDataList] = useState([]);
    const [listPreview, setListPreview] = useState([]);

    useEffect(() =>{
        setDataList(data_options.data_list)
    },[])

function typeSearch(value) {
    let key = value.target.value.toLowerCase();
    let tempArr = []
    
    if(!key.trim("")){
         setListPreview([])
        return;
    }

   tempArr = dataList.filter(item=>
        item.name.toLowerCase().includes(key) || item.languages.toLowerCase().includes(key)
    );
    setListPreview(tempArr)
}

function clearSearchBar(){
    let searchBar = document.getElementById("search-bar");
    searchBar.value = ""
    setListPreview([])

}
 return(
    <div>        
        <div className="search-body">
            <input id="search-bar" type="search" placeholder="Name or language..." onChange={typeSearch}/>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search icon-search" viewBox="0 0 16 16">
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
            </svg>
            {
                (listPreview.length > 0)?
                    <div className="search-list">
                        {
                        listPreview.map((item, index) =>(
                        <Link className="option-body" to={item.go_to} key={index} onClick={clearSearchBar}>
                            <picture>
                                <img src={require(`/src/assets/images/${item.icon}`)}/>
                            </picture>
                            <small>{item.name}</small>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5"/>
                                <path fill-rule="evenodd" d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0z"/>
                            </svg>
                        </Link>
                        ))}
                    </div>
                :
                    <></>
            }
        </div>
    </div>
    )
}

export default SearchComponent;
import React from "react";
import { useEffect, useState } from "react";
import data_art from '../../assets/data/data_arts.json'


import ArtCard from "../../components/card_arts"
import ModalArt from "../../components/modal_arts"
import Footer from "../../components/footer";

import '../../assets/styles/arts.css'

function Arts({onArts}){
    const [artList, setArtList] = useState({})
    const [focusedArt, setFocusedArt] = useState({})
    useEffect(() => {
        document.title="Arts"
        onArts()
        fill_art_list()
    },[]);

    function fill_art_list(){
        setArtList(data_art)
    }

    function open_modal(image){
        document.getElementById("modal-activator").click()
        setFocusedArt(image)
    }

    return(
        <div className="App art-page">
            <ModalArt data={focusedArt}/>
            <div className="container-arts">
            <h1 className="title-section">{"<Arts/>"}</h1>
            <br></br>
            <hr></hr>
            <br></br>
                {
                     (artList.art_cardlist) ? 
                        artList.art_cardlist.map((item, index)=>(
                            <ArtCard art_class={(index%2==0)?'type_one':'type_two'} data={item} key={index} onModalActivate={open_modal}/>
                        ))
                        :<div></div>
                }
            </div>
            <Footer/>
        </div>
    );
}

export default Arts;
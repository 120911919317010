import React from "react";
import { useEffect, useState } from "react";

import '../../App.css';
import '../../assets/styles/experience.css';
import '../../components/footer/footer.css'


import DataExperience from "../../assets/data/data_experience.json"
import CardExperiece from "../../components/card_experience";
import ModalExperience from '../../components/modal_experience';
import Footer from "../../components/footer";

function Experience({onExperience}){
    const [dataExp, setDataExp] = useState([])
    const [currentItem, setCurrentItem] = useState({})

    useEffect(()=>{
        document.title = "Experience";
        onExperience()
        setDataExp(DataExperience)
    },[]);

    function displayModal(item){
        const modal_controller = document.getElementById("modal-displayer");
        modal_controller.click();
        setCurrentItem(item)
    }
    return (
        <div className="App bg-experience">
            <ModalExperience data={currentItem}/>
            <div className="container-experience">
                <h1 className="title-section">{"<Experience/>"}</h1>
               <br></br>
               <hr></hr> 
               <br></br>
                {
                    (dataExp.data_experience)? 
                    dataExp.data_experience.map((item, index)=>(
                        <CardExperiece data={item} key={index} onMoreInfo={displayModal}/>
                    )): <div></div>
                }
            </div>
            <div className="footer-to-bottom">
             <Footer/>
            </div>
        </div>
    );
}

export default Experience;